<template>
  <div class="testimonials section section--dark">
    <div class="section-text">
      <h3 class="title">{{ $t("testimonials.title") }}</h3>
      <div class="cards">
        <div class="card" v-for="(n, i) in 3" :key="i">
          <div class="header">
            <div class="image"></div>
            <div>
              <p class="name">{{ $t(`testimonials.posts[${i}].name`) }}</p>
            </div>
          </div>

          <p class="message">
            {{ $t(`testimonials.posts[${i}].message`) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.testimonials {
  .section-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .subtitle {
    text-align: center;
    padding-bottom: 2rem;
  }
}

.cards {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
}

.card {
  background-color: rgba(243, 244, 246, 1);
  padding: 2rem;
  max-width: 320px;
  border-radius: 10px;
  box-shadow: 0 20px 30px -20px rgba(5, 5, 5, 0.24);
  transition: all 200ms ease-in-out;
  &:hover {
    box-shadow: 0 20px 30px -10px rgba(5, 5, 5, 0.24);
  }
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.header .image {
  height: 4rem;
  width: 4rem;
  border-radius: 9999px;
  object-fit: cover;
  background-color: #e9c6c0;
}

.name {
  margin-top: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, 1);
}

.message {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 1rem;
  font-size: 1rem;
  color: rgba(107, 114, 128, 1);
}
</style>
