<template>
  <div class="landing section section--light" id="landing">
    <img
      src="../assets/images/landing.jpg"
      class="section-image landing-image"
      alt=""
    />
    <div class="landing-title">
      <h1>
        <span class="title">{{ $t("company-name") }}</span>
        <br />
        <span class="slogan">{{ $t("company-slogan") }}</span>
        <br />
        <span class="owner"> {{ $t("owner-name-with-title") }}</span>
      </h1>
      <i
        @click="scrollTo('about-me')"
        class="fa-solid fa-chevron-down icon"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.landing {
  width: 100%;
  .landing-image {
    border-radius: 50%;
  }

  .landing-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-weight: 600;
      line-height: 0.8;
      text-align: center;
      font-size: 5rem;
      .title {
        // text-transform: uppercase;
        font-family: "Catary", sans-serif;
        font-size: 6rem;
      }
      .slogan {
        font-size: 2.5rem;
        font-weight: 400;
        text-transform: uppercase;
      }
      .owner {
        font-size: 2rem;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .icon {
      margin-top: 1rem;
      font-size: 3rem;
      cursor: pointer;
    }
  }
}
</style>
