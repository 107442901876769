<template>
  <div class="home">
    <LandingPage />
    <AboutMe />
    <MyBackground />
    <AboutClients />
    <AboutSessions />
    <TestimonialsPage />
    <HowToBook />
    <ScheduleAndContact />
    <FooterComponent />
  </div>
</template>

<script>
import LandingPage from "@/components/LandingPage.vue";
import AboutMe from "@/components/AboutMe.vue";
import AboutClients from "@/components/AboutClients.vue";
import AboutSessions from "@/components/AboutSessions.vue";
import TestimonialsPage from "@/components/TestimonialsPage.vue";
import ScheduleAndContact from "@/components/ScheduleAndContact.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import MyBackground from "@/components/MyBackground.vue";
import HowToBook from "@/components/HowToBook.vue";
export default {
  name: "HomeView",
  components: {
    LandingPage,
    AboutMe,
    AboutClients,
    AboutSessions,
    TestimonialsPage,
    ScheduleAndContact,
    FooterComponent,
    MyBackground,
    HowToBook,
  },
};
</script>
