<template>
  <div class="section about section--light" id="about-sessions">
    <img
      src="../assets/images/about_sessions.jpg"
      class="section-image"
      alt=""
    />
    <div class="section-text">
      <h3>{{ $t("navBar.about_sessions") }}</h3>
      {{ $t("about_sessions.body") }}
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
