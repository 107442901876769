<template>
  <div class="section section--light" id="my-background">
    <img
      src="../assets/images/my_background.jpg"
      class="section-image"
      alt=""
    />
    <div class="section-text">
      <h3>{{ $t("navBar.my_background") }}</h3>
      {{ $t("my_background.body") }}
      <a
        href="https://linkedin.com/in/ula-jadowska-quinn-302b586b"
        target="_blank"
        >{{ $t("my_background.link_test") }}</a
      >
    </div>
  </div>
</template>
