<template>
  <div
    class="schedule-and-contact section section--dark"
    id="schedule-and-contact"
  >
    <schedule />
    <contact />
  </div>
</template>

<script>
import schedule from "@/components/SchedulePage.vue";
import contact from "@/components/ContactPage.vue";
export default {
  components: {
    schedule,
    contact,
  },
};
</script>

<style lang="scss" scoped></style>
