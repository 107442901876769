<template>
  <div class="footer">
    <div class="footer-section logo">
      <img src="../assets/images/icon.png" alt="" />
      <p>© {{ $t("company-name") }} {{ new Date().getFullYear() }}</p>
    </div>
    <!-- <div class="footer-section links">
      <h4>{{ $t("footer.main.title") }}</h4>
      <a v-for="(link, i) in mainLinks" :href="link.link" :key="i">{{
        $t(`footer.main.${link.title}`)
      }}</a>
    </div> -->
    <!-- <div class="footer-section links">
      <h4>{{ $t("footer.legal.title") }}</h4>
      <router-link v-for="(link, i) in legalLinks" :to="link.link" :key="i">{{
        $t(`footer.legal.${link.title}`)
      }}</router-link>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      mainLinks: [
        {
          title: "home",
          link: "/",
        },
        {
          title: "testimonials",
          link: "/testimonials",
        },
        {
          title: "contact",
          link: "/contact",
        },
        {
          title: "schedule",
          link: "/schedule",
        },
        {
          title: "about",
          link: "/about",
        },
      ],
      legalLinks: [
        {
          title: "privacy_policy",
          link: "/privacy",
        },
        {
          title: "terms_of_use",
          link: "/terms",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);

  .footer-section {
    display: flex;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
    &.logo {
      align-items: center;
    }
    &.links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    h4 {
      margin-bottom: 0.5rem;
    }

    a {
      color: white;
    }

    img {
      width: 7rem;
      height: 7rem;
      margin-right: 1rem;
    }
  }
}
</style>
