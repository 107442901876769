<template>
  <div class="section about section--dark" id="about-clients">
    <img
      src="../assets/images/about_clients.jpg"
      class="section-image above"
      alt=""
    />
    <div class="section-text">
      <h3>{{ $t("navBar.about_clients") }}</h3>
      {{ $t("about_clients.body") }}
    </div>
    <img
      src="../assets/images/about_clients.jpg"
      class="section-image below"
      alt=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
