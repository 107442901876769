<template>
  <div class="schedule" id="schedule">
    <h3>{{ $t("schedule_a_call") }}</h3>
    <div
      class="calendly-inline-widget"
      data-url="https://calendly.com/u-jadowska-quinn/online-session?background_color=f5f5f5&primary_color=f9b5a9"
      style="width: 100%; height: 900px"
    ></div>
  </div>
</template>

<script>
export default {
  name: "SchedulePage",
};
</script>

<style lang="scss" scoped>
.schedule {
  width: 100%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
