<template>
  <div class="section section--dark" id="about-me">
    <img
      src="../assets/images/about_me.jpg"
      class="section-image above"
      alt=""
    />
    <div class="section-text">
      <h3>{{ $t("navBar.about_me") }}</h3>
      {{ $t("about_me.body") }}
    </div>
    <img
      src="../assets/images/about_me.jpg"
      class="section-image below"
      alt=""
    />
  </div>
</template>
