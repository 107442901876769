import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import App from "./App.vue";
import router from "./router";

import en from "./languages/en.json";
import pl from "./languages/pl.json";

const i18n = createI18n({
  locale: "en",
  messages: {
    en: en,
    pl: pl,
  },
});

createApp(App).use(i18n).use(router).mount("#app");
