<template>
  <div class="contact" id="contact">
    <h3>{{ $t("contact_title") }}</h3>
    <Transition name="fade-out-in">
      <div
        class="banner"
        v-if="bannerShow"
        :style="{ backgroundColor: `#${bannerColor}` }"
      >
        {{ bannerMessage }}
      </div>
    </Transition>
    <div class="modal__body">
      <div class="input">
        <input class="input__field" type="text" v-model="name" />
        <p class="input__description">
          {{ $t("contact_form_name_description") }}
        </p>
      </div>
      <div class="input">
        <input class="input__field" type="email" v-model="email" />
        <p class="input__description">
          {{ $t("contact_form_email_description") }}
        </p>
      </div>
      <div class="input">
        <input class="input__field" type="text" v-model="subject" />
        <p class="input__description">
          {{ $t("contact_form_subject_description") }}
        </p>
      </div>
      <div class="input">
        <textarea
          class="input__field input__field--textarea"
          v-model="message"
        ></textarea>
        <p class="input__description">
          {{ $t("contact_form_message_description") }}
        </p>
      </div>
    </div>
    <vue-turnstile site-key="0x4AAAAAAAWF5WdaZCXy3cam" v-model="token" />
    <div class="modal__footer">
      <button class="button button--primary" @click="send">
        {{ $t("contact_form_send") }}
      </button>
    </div>
  </div>
</template>

<script>
import VueTurnstile from "vue-turnstile";
import axios from "axios";
export default {
  components: { VueTurnstile },
  name: "ContactPage",
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      token: "",
      bannerMessage: "Test text",
      bannerColor: "a54f4f",
      bannerShow: false,
    };
  },
  methods: {
    async send() {
      await axios
        .post("/api/contact", {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message,
          token: this.token,
          tracker: localStorage.getItem("tracker"),
        })
        .then(() => {
          this.name = "";
          this.email = "";
          this.subject = "";
          this.message = "";
          this.bannerColor = "8de395";
          this.bannerMessage = "Message sent successfully!";
          this.bannerShow = true;
          setTimeout(() => {
            this.bannerShow = false;
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.bannerColor = "a54f4f";
          this.bannerMessage = error.response.data.error;
          this.bannerShow = true;
          setTimeout(() => {
            this.bannerShow = false;
          }, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  width: 100%;
  height: 900px;
}
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s ease;
  font: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

.button--icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  border-radius: 0.25rem;
}

.button--icon:focus,
.button--icon:hover {
  background-color: #ededed;
}

.button--primary {
  background-color: #a54f4f;
  color: #fff;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
}

.button--primary:hover {
  background-color: #f9b5a9;
}

.input {
  display: flex;
  flex-direction: column;
}

.input + .input {
  margin-top: 0rem;
}

.input__label {
  font-weight: 700;
  font-size: 0.875rem;
}

.input__field {
  display: block;
  margin-top: 0.5rem;
  border: 1px solid #f9b5a9;
  border-radius: 0.25rem;
  padding: 0.75rem 0.75rem;
  transition: 0.15s ease;
}

.input__field:focus {
  outline: none;
  border-color: #ea7f6c;
  box-shadow: 0 0 0 1px #ea7f6c, 0 0 0 4px rgba(0, 125, 171, 0.25);
}

.input__field--textarea {
  min-height: 100px;
  max-width: 100%;
}

.input__description {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: #8d8d8d;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  color: whitesmoke;
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
